import 'owl.carousel';
import moment from 'moment';

jQuery( document ).ready( function ( $ ) {
	$( 'nav>ul>li>a.local' ).click( function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		$( 'html, body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 1500 );
	} );

	$( '.owl-carousel' ).owlCarousel( {
		items: 1,
		video: true,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );

	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/id_12488924/events?app_id=45PRESS_ALEX_HALL',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					let location = event.venue.city + ', ' + event.venue.region;

					if ( location === ', ' ) {
						location = '';
					}

					html += '<div>';
					html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM D' ) + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						html += '<a href="' + offer.url + '" target="_blank" class="btn btn-brown">' + offer.type + '</a>';
					}
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	} );
} );
